<script>
import VueContentLoading from '../VueContentLoading.vue'

export default {
  components: {
    VueContentLoading
  },
  props: {
    header: {
      default: true,
      type: Boolean
    },
    rows: {
      default: 5,
      type: Number
    },
    primaryColor: {
      default: '#f0f0f0',
      type: String
    },
    columns: {
      default: 4,
      type: Number
    }
  },
  computed: {
    height () {
      return (this.rows * 30) - 20
    },
    width () {
      return ((this.columns - 1) * 20) + 10 + (this.columns * 100)
    }
  },
  methods: {
    getXPos (column) {
      return 5 + ((column - 1) * 100) + ((column - 1) * 20)
    },
    getYPos (row) {
      return (row - 1) * 30
    }
  }
}
</script>

<template>
  <div>
    <vue-content-loading v-bind="$attrs" :height="height" :primary="primaryColor" :width="width">
      <template v-for="r in rows">
        <template v-for="c in columns">
          <rect :key="r + '_' + c" :width="100" :x="getXPos(c)" :y="getYPos(r)" height="10" rx="3" ry="3"/>
        </template>
        <rect v-if="r < rows" :key="r + '_l'" :width="width" :y="getYPos(r) + 20" height="1" x="0"/>
      </template>
    </vue-content-loading>
  </div>
</template>
