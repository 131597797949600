<template>
  <div class="card icon-box mb-3 card-service">
    <div class="card-body align-items-center">
      <div class="icon">
        <img v-if="!iconPath"
             :src="require(`@/assets/img/icons/${iconName}`)" alt="" class="image-icon"/>
        <img v-if="iconPath" :src="iconPath | makeAbsUrl" alt="" class="image-icon"/>
      </div>
      <div class="text-content">
        <h6 class="card-tagline">{{ title }}</h6>
        <h4 v-show="value !== ''" class="card-title">{{ value.toLocaleString() }} <span> {{ dataUnit }}</span>
        </h4>
        <p v-if="subtitle !== ''" class="card-desc font-18 text-pre-wrap" v-html="subtitle"></p>
      </div>
    </div>
  </div>
</template>

<script>
import UtilityMixin from '@/mixins/utility-mixin'

export default {
  name: 'SmallCardWithIcon',
  mixins: [UtilityMixin],
  props: {
    iconName: {
      type: String,
      required: false,
      default: 'nut.svg'
    },
    iconPath: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    value: {
      type: [Number, String],
      required: false
    },
    dataUnit: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
