<template>
  <svg
    :style="svg"
    :viewBox="viewbox"
    preserveAspectRatio="xMidYMid meet">
    <rect
      :clip-path="rect.clipPath"
      :height="height"
      :style="rect.style"
      :width="width"
      x="0"
      y="0"
    />

    <defs>
      <clipPath :id="clipPathId">
        <slot>
          <rect height="70" rx="5" ry="5" width="70" x="0" y="0"/>
          <rect height="13" rx="4" ry="4" width="300" x="80" y="17"/>
          <rect height="10" rx="3" ry="3" width="250" x="80" y="40"/>
          <rect height="10" rx="3" ry="3" width="350" x="0" y="80"/>
          <rect height="10" rx="3" ry="3" width="400" x="0" y="100"/>
          <rect height="10" rx="3" ry="3" width="360" x="0" y="120"/>
        </slot>
      </clipPath>

      <linearGradient :id="gradientId">
        <stop :stop-color="primary" offset="0%">
          <animate :dur="formatedSpeed" attributeName="offset" repeatCount="indefinite" values="-2; 1"/>
        </stop>

        <stop :stop-color="secondary" offset="50%">
          <animate :dur="formatedSpeed" attributeName="offset" repeatCount="indefinite" values="-1.5; 1.5"/>
        </stop>

        <stop :stop-color="primary" offset="100%">
          <animate :dur="formatedSpeed" attributeName="offset" repeatCount="indefinite" values="-1; 2"/>
        </stop>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
const validateColor = color => /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/.test(color)

export default {
  name: 'VueContentLoading',
  props: {
    rtl: {
      default: false,
      type: Boolean
    },
    speed: {
      default: 2,
      type: Number
    },
    width: {
      default: 400,
      type: Number
    },
    height: {
      default: 130,
      type: Number
    },
    primary: {
      type: String,
      default: '#f0f0f0',
      validator: validateColor
    },
    secondary: {
      type: String,
      default: '#e0e0e0',
      validator: validateColor
    }
  },
  computed: {
    viewbox () {
      return `0 0 ${this.width} ${this.height}`
    },
    formatedSpeed () {
      return `${this.speed}s`
    },
    gradientId () {
      return `gradient-${this.uid}`
    },
    clipPathId () {
      return `clipPath-${this.uid}`
    },
    svg () {
      if (this.rtl) {
        return {
          transform: 'rotateY(180deg)'
        }
      }
    },
    rect () {
      return {
        style: {
          fill: 'url(#' + this.gradientId + ')'
        },
        clipPath: 'url(#' + this.clipPathId + ')'
      }
    }
  },
  data: () => ({
    uid: null
  }),
  created () {
    this.uid = this._uid
  }
}
</script>
