<script>
import VueContentLoading from '../VueContentLoading.vue'

export default {
  components: {
    VueContentLoading
  }
}
</script>

<template>
  <vue-content-loading v-bind="$attrs" :height="225" :width="300">
    <rect height="170" rx="3" ry="3" width="300" x="0" y="0"/>
    <rect height="45" rx="2" ry="2" width="35" x="0" y="180"/>
    <rect height="15" rx="2" ry="2" width="150" x="45" y="180"/>
    <rect height="10" rx="2" ry="2" width="100" x="45" y="203"/>
  </vue-content-loading>
</template>
