<template>
  <ul class="nav nav-tabs nav-justified">
    <li class="nav-item">
      <a :class="{ active: activeTab === 'ALL' }"
         class="nav-link text-center text-dark"
         data-toggle="tab"
         href="#tab-1" role="tab" @click="setCountryToPath('ALL')">
        <img alt="" height="26px" src="@/assets/img/Globe.png"/><br>All Countries
      </a>
    </li>
    <li v-for="country in countries" :key="country.id" class="nav-item">
      <a :class="{ active: activeTab === country.public_code }"
         :href="'#country-tab-' + country.id"
         class="nav-link text-dark"
         data-toggle="tab" role="tab" @click="setCountryToPath(country.public_code)">
        <img v-if="country.photo" :src="country.photo.relative_url | makeAbsUrl" alt="" height="26px" width="40px"/>
        <img v-if="!country.photo" alt="" height="26px" src="@/assets/img/Globe.png" width="26px"/>
        <br><span class="country-name-txt">{{ country.name }}</span><br>
      </a>
    </li>
    <li v-for="_i in [uuidV4(), uuidV4(),uuidV4(),uuidV4(),uuidV4()]" v-show="countries.length === 0" :key="_i"
        class="nav-item">
      <a class="nav-link text-dark" href="#">
        <content-loader
          :height="70"
          :speed="1"
          :width="180"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect height="6" rx="3" ry="3" width="380" x="232" y="-110"/>
          <rect height="44" rx="0" ry="0" width="58" x="4" y="4"/>
          <rect height="5" rx="0" ry="0" width="5" x="119" y="20"/>
          <rect height="2" rx="0" ry="0" width="0" x="32" y="13"/>
          <rect height="9" rx="0" ry="0" width="60" x="3" y="56"/>
        </content-loader>
      </a>
    </li>
  </ul>
</template>

<script>
import UtilityMixin from '@/mixins/utility-mixin'
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'CountryTab',
  mixins: [UtilityMixin],
  components: { ContentLoader },
  props: {
    countries: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      activeTab: 'ALL'
    }
  },
  mounted () {
    this.activeTab = this.$route.query.tab || 'ALL'
  },
  methods: {
    setCountryToPath (countryCode) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.params,
          tab: countryCode
        }
      })
    }
  },
  watch: {
    '$route.query.tab' (newValue) {
      this.activeTab = newValue || 'ALL'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
