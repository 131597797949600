<script>
import VueContentLoading from '../VueContentLoading.vue'

export default {
  components: {
    VueContentLoading
  }
}
</script>

<template>
  <vue-content-loading v-bind="$attrs" :height="120" :width="300">
    <rect height="10" rx="3" ry="3" width="250" x="0" y="0"/>
    <rect height="10" rx="3" ry="3" width="220" x="20" y="20"/>
    <rect height="10" rx="3" ry="3" width="170" x="20" y="40"/>
    <rect height="10" rx="3" ry="3" width="250" x="0" y="60"/>
    <rect height="10" rx="3" ry="3" width="200" x="20" y="80"/>
    <rect height="10" rx="3" ry="3" width="80" x="20" y="100"/>
  </vue-content-loading>
</template>
