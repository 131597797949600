<script>
import VueContentLoading from '../VueContentLoading.vue'

export default {
  components: {
    VueContentLoading
  }
}
</script>

<template>
  <vue-content-loading v-bind="$attrs" :height="80" :width="300">
    <rect height="10" rx="3" ry="3" width="70" x="0" y="0"/>
    <rect height="10" rx="3" ry="3" width="100" x="80" y="0"/>
    <rect height="10" rx="3" ry="3" width="10" x="190" y="0"/>
    <rect height="10" rx="3" ry="3" width="130" x="15" y="20"/>
    <rect height="10" rx="3" ry="3" width="130" x="155" y="20"/>
    <rect height="10" rx="3" ry="3" width="90" x="15" y="40"/>
    <rect height="10" rx="3" ry="3" width="60" x="115" y="40"/>
    <rect height="10" rx="3" ry="3" width="60" x="185" y="40"/>
    <rect height="10" rx="3" ry="3" width="30" x="0" y="60"/>
  </vue-content-loading>
</template>
